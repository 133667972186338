import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import bodyRenderer from 'utils/bodyRenderer';
import { getSectionAnchors } from 'utils/helpers';

import bodySections from './bodySections';
import { ICampaignPagePlainProps } from './model';

import './CampaignPagePlain.scss';

const CampaignPagePlain: FC<ICampaignPagePlainProps> = ({ data, pageContext: { breadcrumbs } }) => {
  const {
    page: {
      nodes: [
        { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, lang, body },
      ],
    },
    siteSettings,
    header,
    footer,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    hcpPopup,
    pageLangs: { nodes: langs },
  } = data;

  const sectionAnchors = getSectionAnchors(body);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
      }}
      siteSettings={siteSettings}
      header={header}
      footer={footer}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      hcpPopup={hcpPopup}
    >
      <div className="campaign-page-plain-holder">
        {bodyRenderer(body, bodySections, {
          sectionAnchors,
          breadcrumbs,
          ariaLabelPrev: siteSettings.ariaLabelPrev,
          ariaLabelNext: siteSettings.ariaLabelNext,
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!, $pageIdRegex: String) {
    page: allCampaignPagePlain(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        lang
        ...FragmentSeo
        body {
          structure
          properties {
            ...FragmentPainBanner
            ...FragmentPainBannerExtendTitle
            ...FragmentsFacts
            ...FragmentsReports
            ...FragmentTextWithImage
            ...FragmentsPledge
            ...FragmentReadReport
            ...FragmentsTimeline
            ...FragmentPainVideoSection
            ...FragmentVideoIframe
            ...FragmentsBiases
            ...FragmentEvidence
            ...FragmentPrevalence
            ...FragmentSurvey
            ...FragmentCaseStudy
            ...FragmentPodcast
            ...FragmentExpert
            ...FragmentPartnerships
            ...FragmentNews
            ...FragmentRelatedPages
            ...FragmentRelatedInfo
            ...FragmentHCPBanner
            ...FragmentAnchorSubmenu
            ...FragmentPainPassBanner
            ...FragmentRememberSection
            ...FragmentScale
            bulletColor {
              ...FragmentColorProps
            }
            text
            sectionTheme
            sectionLandmark
          }
        }
      }
    }
    pageLangs: allUmbracoHome(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default CampaignPagePlain;
